import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import {CommonModule} from "@angular/common";
import { FileuploadService } from '../fileupload.service';

@Component({
  selector: 'app-invoice-upload',
  templateUrl: './invoice-upload.component.html',
  styleUrls: ['./invoice-upload.component.scss']
})
export class InvoiceUploadComponent {
  // Storing files in a File array
  files?: File[];
  isMultiple = false;

  // Injecting uploadservice
  constructor(private uploadService: FileuploadService) { }

  // Function to use the service to upload files
  private month: string | undefined;
  uploadFiles(filesElement: HTMLInputElement) {

    // Check whether the files array is not undefined
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const formattedDate = `${year}-${month}`;
    console.log("Uploading invoice for month: ", formattedDate);
    if (this.files) {
      this.uploadService.uploadFile(this.files, formattedDate)
        .subscribe((res: any) => {
          alert("Invoice is uploaded successfully!");

          // Resetting the input file tag
          filesElement.value = '';
        });
    } else {
      alert('Please select invoice to upload!');
    }
  }
  changeFiles(event: any) {

    // On file change set it to files array
    this.files = event.target.files;
  }
}
